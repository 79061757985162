import Box from 'components/Box/Box';
import { useWebConfig } from 'features/configuration/queries';
import { ReactNode } from 'react';
import { STATIC_IMAGES } from '../../../../constants/media';
import Image from '../../../Image/Image';
import { StyledProps, StyledStatusPageContentSection, StyledStatusPageErrorImageWrap } from './StatusPage.styled';

export interface StatusPageProps extends StyledProps {
  content?: ReactNode;
  description?: string;
  image?: string;
  staticImage?: ReactNode;
  title?: string;
}

const StatusPage = ({ content, description, image, imageSize = '40vh', staticImage, title }: StatusPageProps) => {
  let imageNode;
  const { data: webConfig } = useWebConfig();

  const staticImages = webConfig?.staticImages;

  const defaultErrorImage = staticImages?.find((img) => img.code === STATIC_IMAGES.ERROR);

  if (staticImage) {
    imageNode = staticImage;
  } else if (image || defaultErrorImage) {
    imageNode = <Image alt={title} layout="fill" objectFit="contain" src={(image || defaultErrorImage?.url) ?? ''} />;
  }

  return (
    <Box className="statusPage" marginTop={100}>
      <div className="grid gap-2 md:grid-cols-2">
        <StyledStatusPageContentSection className="content-section">
          <section className="error-content">
            {title && <h1 className="title">{title}</h1>}

            {description && <p className="lead">{description}</p>}

            {/* Dynamic content */}
            {content}
          </section>
        </StyledStatusPageContentSection>

        <div className="image-section">
          <StyledStatusPageErrorImageWrap className="error-img-wrap" imageSize={imageSize}>
            {imageNode}
          </StyledStatusPageErrorImageWrap>
        </div>
      </div>
    </Box>
  );
};

export default StatusPage;
