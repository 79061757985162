import styled, { css } from 'styled-components';
import { breakpointUp, color, fontSize, spacer } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import CheckList from '../../../Layout/CheckList/CheckList';

export const StyledStatusPageContentSection = styled.div`
  height: 100%;
  justify-content: center;
  display: flex;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      align-items: center;
    `,
  )}
`;

export interface StyledProps {
  imageSize?: string;
}

export const StyledStatusPageErrorImageWrap = styled.div<StyledProps>`
  position: relative;
  height: ${({ imageSize }) => imageSize};
`;

export const StyledStatusPageCheckList = styled(CheckList)`
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  svg {
    color: ${color('secondary-300')};
    fill: ${color('primary-300')};
    font-size: ${fontSize(125)};
    margin-right: ${spacer(100)};
  }
`;
