import PageLayout from 'components/PageLayout';
import SEO from 'components/Seo/SEO';
import NotFoundPage from 'components/StatusPages/Page/NotFoundPage/NotFoundPage';
import { ReactNode } from 'react';
// FIXME: Refactor NotFoundPage to TSX
const Container404 = () => (
  <SEO>
    <main>
      <NotFoundPage />
    </main>
  </SEO>
);

export default Container404;

Container404.getLayout = function getLayout(page: ReactNode) {
  return <PageLayout>{page}</PageLayout>;
};
