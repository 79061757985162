import { useWebConfig } from 'features/configuration/queries';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import { ICON_NAMES } from '../../../../constants/icon';
import { STATIC_IMAGES } from '../../../../constants/media';
import Flex from '../../../Flex/Flex';
import CheckListItem from '../../../Layout/CheckList/CheckListItem/CheckListItem';
import StaticImage from '../../../Layout/StaticImage/StaticImage';
import Link from '../../../Link/Link';
import StatusPage from '../StatusPage/StatusPage';
import { StyledStatusPageCheckList } from '../StatusPage/StatusPage.styled';

const Icon = dynamic(() => import('../../../Icon/Icon'));

const NotFoundPage = () => {
  const { formatMessage } = useIntl();
  const title = formatMessage({
    id: 'status_pages_not_found_title',
  });
  const description = formatMessage({
    id: 'status_pages_not_found_description',
  });

  const { data: webConfig } = useWebConfig();

  const content = (
    <StyledStatusPageCheckList hasIcons>
      <CheckListItem>
        <Link underlined url="/">
          <Flex>
            <Icon color="primary-300" name={ICON_NAMES.CHECK_DOUBLE} size={125} />
            {formatMessage({
              id: 'status_pages_not_found_checklist_1',
            })}
          </Flex>
        </Link>
      </CheckListItem>
      <CheckListItem>
        <Flex>
          <Icon color="primary-300" name={ICON_NAMES.CHECK_DOUBLE} size={125} />
          {formatMessage({
            id: 'status_pages_not_found_checklist_2',
          })}
        </Flex>
      </CheckListItem>
    </StyledStatusPageCheckList>
  );

  const staticImage = <StaticImage code={STATIC_IMAGES.NO_RESULTS} staticImages={webConfig?.staticImages} />;

  return <StatusPage content={content} description={description} staticImage={staticImage} title={title} />;
};

export default NotFoundPage;
