import { dehydrate } from '@tanstack/react-query';
import { BRAND_SPECIFIC_DEFAULT_LOCALE, DEFAULT_LOCALE } from 'constants/i18n';
import { REVALIDATION_TIME } from 'constants/isr';
import Container404 from 'containers/Error/404Container';
import { beforeGetStaticProps } from 'helpers/AxiosWrapper';
import { pathnames } from 'i18n/pathnames';
import { GetStaticPropsContext } from 'next';

export const getStaticProps = async (context: GetStaticPropsContext) => {
  if (context.locale === DEFAULT_LOCALE) {
    context.locale = BRAND_SPECIFIC_DEFAULT_LOCALE;
  }
  const { queryClient } = await beforeGetStaticProps(context, pathnames.ERROR404);
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: REVALIDATION_TIME,
  };
};

const Custom404 = () => <Container404 />;

export default Custom404;

Custom404.getLayout = Container404.getLayout;
